
<template>


  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          We are launching soon 🚀
        </h2>
        <p class="mb-3">
          We're creating something awesome.
        </p>

      </div>
    </div>
  </div>
</template>
<script>

  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'

  import { mapGetters, mapActions } from "vuex";



  import VueBarcode from 'vue-barcode';
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,

    };
  },
  mounted (){

  },
  components: {
    Loading,
    'barcode': VueBarcode
  },
  methods:{

  }
};
</script>
